import {navigate}  from "gatsby"
import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import {Row, Col, Container } from 'react-bootstrap';
import "../Banner/banner.scss"
import $ from 'jquery'
import Slider from './slider.js'

const Banner = (props) => {
  const [pause, setPause] = useState(true);

  const handleChange = (e) =>{
    if(e != '') {
    setPause(false)
    }
    else {
    setPause(true);
    }
  }

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7500,
    infinite: true,
    fade: false,
    mobileFirst:true,
    autoplay:true
    // draggable:false
  }
  const thumbsetting = {
    dots: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 7500,
    autoplay:true,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          autoplaySpeed: 7500,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplaySpeed: 7500,
          slidesToShow: 2,
        },
      },
    ],
  }

  const settingsone = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 7500,
    infinite: true,
    fade: false,
    mobileFirst:true,
    autoplay:true
  }
  const thumbsettingone = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplaySpeed: 7500,
    autoplay:false,
    infinite: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          autoplaySpeed: 7500,
        },
      },
      {
        breakpoint: 767,
        settings: {
          autoplaySpeed: 7500,
          slidesToShow: 2,
        },
      },
    ],
  }


  return (
  <>
      <div className="home-banner components--Home--Banner--banner">
          {
            pause == true ? (
              <Slider data={props.data} settings={settings} thumbsetting={thumbsetting} handleChange={handleChange} />
            ) : (
              <Slider data={props.data} settings={settingsone} thumbsetting={thumbsettingone} handleChange={handleChange} />
            )
          }
        </div>
        <div className="homebanner_stampduty_strip">
          <Container>
            <Row>
              <Col md={12}>
                <div className="stampduty_banner_strip">
                  <h3>REFORM STAMP DUTY NOW!</h3>
                  <a className="btn" href="https://www.change.org/p/reform-stamp-duty-tax-uk" target="_blank">SIGN THE PETITION HERE</a>
                </div>
              </Col>
            </Row>
          </Container>
      </div>
  </>
    )
}

export default Banner