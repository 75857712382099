import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import "../Banner/banner.scss"
import ReactMarkdown from "react-markdown"
import Slider from 'react-slick';
import SearchInputBox from '../../predictive-search/search-inputbox';
import { rentPrices, salesPrices, commercialPrices, landPrices, newhomesPrices } from '../../../utils/priceList';
import Select from "react-select";
import $ from 'jquery'
import { navigate } from "@reach/router"
import { getSrc } from "gatsby-plugin-image"
import { isMobile, browserName } from "react-device-detect";

import CTA from './banner-cta';

// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const imageVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const SliderNav = (props) => {
  let [nav1, setNav1] = useState(null)
  let [nav2, setNav2] = useState(null)
  let [areaVal, setAreaVal] = useState("")
  let [priceOptions, setPriceOptions] = useState(salesPrices)
  let [price, setPrice] = useState(null)


  const settings = props.settings
  const thumbsetting = props.thumbsetting

  //  console.log("autoplay", props.autoplay)

  useEffect(() => {
    $('.react-autosuggest__input').on('click', function () {
      if (props.handleChange) {
        props.handleChange("stop");
      }
    });

  }, [areaVal])

  const submitForm = () => {
    let url = "/";
    var searcValue = $(".property_search_banner .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    var ptype = $(".ptype").val()
    if (ptype == 'buy') {
      url = "/property/for-sale/"
    } else if (ptype == 'rent') {
      url = "/property/to-rent/"
    } else if (ptype == 'commercial') {
      url = "/property/commercial/for-sale/"
    } else if (ptype == 'newhomes') {
      url = "/property/for-sale/in-london/type-new-home/"
    } else {
      url = "/property/for-sale/in-london/type-land/"
    }


    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else if ((ptype == 'newhomes' || ptype == 'land') && searcValue === "") {
      url = url
    } else {
      url += "in-london/"
    }

    if (price) {
      url += "under-" + price + "/"
    }

    navigate(url);

  }
  const submitMobileForm = () => {
    let url = "/";
    var searcValue = $(".property_search_banner_mobile .react-autosuggest__input").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase(); 

    var ptype = $(".ptype_mobile").val()
    if (ptype == 'buy') {
      url = "/property/for-sale/"
    } else if (ptype == 'rent') {
      url = "/property/to-rent/"
    } else if (ptype == 'commercial') {
      url = "/property/commercial/for-sale/"
    } else if (ptype == 'newhomes') {
      url = "/property/new-homes/for-sale/"
    } else {
      url = "/property/for-sale/in-london/type-land/"
    }

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else if ((ptype == 'newhomes' || ptype == 'land') && searcValue === "") {
      url = url
    } else {
      url += "in-london/"
    }

    if (price) {
      url += "under-" + price + "/"
    }  
   
    navigate(url); 

  }

  function changeSearchType(e) {
    console.log(e.target.value, 'target.value')
    var ptype = e.target.value
     
    setPrice(null)
    if (ptype === 'buy') {
      setPriceOptions(salesPrices)
    } else if (ptype === 'rent') {
      setPriceOptions(rentPrices)
    } else if (ptype === 'land') {
      setPriceOptions(landPrices)
    } else if (ptype === 'newhomes') {
      setPriceOptions(newhomesPrices)
    } else if (ptype === 'commercial') {
      setPriceOptions(commercialPrices)
    } else {
      setPriceOptions(salesPrices)
    }

  }


  //console.log("areaVal==>", areaVal)

  return (
    <>
      <div className="home-slider-banner">
        <Slider
          className="banner-slider"
          {...settings}
          asNavFor={nav2}
          ref={slider => setNav1(slider)}
        >
          {props.data.map((item, i) => {
            let url;
            let image = getSrc(item.Image)
            let mobile_image = getSrc(item.Mobile_Image)
            return (
              <div className="banner-item" key={i}>
                <div className="desktop-banner-search-block desk-block">
                  <picture>
                    <source media="(min-width:1699px)" srcset={image} />
                    <source media="(min-width:992px)" srcset={image} />
                    <source media="(min-width:768px)" srcset={image} />
                    <img variants={imageVariants} className="banner-img" src={image} alt={`${props.page} ${item.Title} banner- Martyn Gerrard`}></img>
                  </picture>
                </div>

                <div className="mobile-banner-search-block mob-block">
                  <picture>
                    <source media="(min-width:1699px)" srcset={item.Mobile_Image ? mobile_image : image} />
                    <source media="(min-width:992px)" srcset={item.Mobile_Image ? mobile_image : image} />
                    <source media="(min-width:768px)" srcset={item.Mobile_Image ? mobile_image : image} />
                    <img variants={imageVariants} className="banner-img" src={item.Mobile_Image ? mobile_image : image} alt={`${props.page} ${item.Title} banner- Martyn Gerrard`}></img>
                  </picture>
                </div>

                <div className={`banner-content ${item.Title === "70 Bags for 70 Years" ? "cta-bottom" : ""}`}>
                  <Container>
                    <Row>
                      {item.Content ? <Col xs={12} sm={12} md={12} lg={9} xl={9}><ReactMarkdown source={item.Content} allowDangerousHtml /></Col> : ''}
                      {i == 0 ?
                        <>
                          <Col md={12}>


                            <div className="desktop-banner-search-block">

                              <div className="input-group property-search-banner testt">
                                <select className="search-select ptype" id="search_dropdown" onChange={(e)=>{changeSearchType(e)}}>
                                  <option value="buy">Buy</option>
                                  <option value="rent">Rent</option>
                                  <option value="land">Land</option>
                                  <option value="newhomes">New Homes</option>
                                  <option value="commercial">Commercial</option>

                                </select>
                                <div className="input-group-prepend property-search-banner-width property-search_banner property_search_banner">
                                  <SearchInputBox handleChange={props.handleChange} setAreaVal={(val) => setAreaVal(val)} type="home-search" trigger="search_btn" />
                                </div>
                                <div className="maxprice-dropdown">
                                  <Select
                                    className="search-select  price-filter-dropdown"
                                    placeholder={"Max Price"}
                                    options={priceOptions}
                                    onChange={e => {

                                      setPrice(e.value)
                                    }}
                                    //isMeonChange={(e)=>{changeSearchType(e)}}
                                    value={price && priceOptions.find(obj => obj.value === price)}
                                    classNamePrefix={"select-opt"}
                                    isSearchable={false}
                                    components={{ DropdownIndicator: () => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator: () => null }}
                                  />
                                </div>
                                <div className="property-search-banner-btn_wrapper">
                                  <a href="javascript:void(0)" onClick={() => { submitForm() }} className="btn search_btn">Search</a>
                                  {/*<a href="javascript:void(0)" className="btn sales_btn">Buy</a>
                                  <a href="javascript:void(0)" className="btn rent_btn">Rent</a> */}
                                </div>
                              </div>

                            </div>
                            <div className="mobile-banner-search-block">

                              <div className="input-group property-search-banner property-search-banner_mobile mobile">
                                <div className="row">
                                  <div className="col-xs-6 cols-1">
                                    <select className="search-select ptype ptype_mobile" id="search_dropdown" onChange={(e)=>{changeSearchType(e)}}>
                                      <option value="buy">Buy</option>
                                      <option value="rent">Rent</option>
                                      <option value="land">Land</option>
                                      <option value="newhomes">New Homes</option>
                                      <option value="commercial">Commercial</option>
                                    </select>
                                  </div>
                                  <div className="col-xs-6 cols-2">

                                    <div className="maxprice-dropdown">
                                      <Select
                                        className="search-select sales_price_list price-filter-dropdown"
                                        placeholder={"Max Price"}
                                        options={priceOptions}
                                        onChange={e => {

                                          setPrice(e.value)
                                        }}
                                        value={price && priceOptions.find(obj => obj.value === price)}
                                        classNamePrefix={"select-opt"}
                                        isSearchable={false}
                                        components={{ DropdownIndicator: () => <i className="icon icon-dropdown-arrow"></i>, IndicatorSeparator: () => null }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="input-group-prepend property-search-banner-width property-search_banner property_search_banner_mobile">
                                  <SearchInputBox handleChange={props.handleChange} setAreaVal={(val) => setAreaVal(val)} type="home-search" trigger="search_btn_mobile" />
                                </div>
                                <div>
                                </div>
                                <div className="property-search-banner-btn_wrapper">
                                  <a href="javascript:void(0)" 
                                  onClick={() => {
                                  
                                     submitMobileForm() 
                                     }} className="btn search_btn_mobile">Search</a>
                                </div>
                              </div>
                            </div>

                          </Col>
                          <Col md={10} className="cta-valuation">
                            <Link className="btn" to={`/market-your-property/book-a-valuation/`}>Book a valuation</Link>
                          </Col>
                        </>
                        :
                        <Col md={12} className="cta-banner-btn-align">{item.Title === "70 Bags for 70 Years" ? <Link className="btn" to={`/news-and-guides/property-news/local-area-news/food-bank-aid-north-london-70-for-70-campaign/`}>{item.CTA_1_Label}</Link> : item.CTA_1_Label ? item.CTA_1_Label === "HIDE_CTA" ? "" :
                          <CTA label={item.CTA_1_Label} url={item.CTA_1_URL} /> : ''}</Col>
                      }
                    </Row>
                  </Container>
                </div>
              </div>
            )
          })}
        </Slider>
        {props.data.length && props.data.length > 1 &&
          <div className="thumbnails">
            <Container>
              <Slider
                className="thumb-slider"
                {...thumbsetting}
                asNavFor={nav1}
                ref={slider => setNav2(slider)}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                {props.data.map((item, i) => {
                  return <div className="thumbitem" kay={i}>
                    <span className="slide-line d-block"></span>
                    <p className="d-none d-md-block">{item.Title}</p>
                  </div>
                })}
              </Slider>
            </Container>
          </div>}
      </div>
    </>
  )
}

export default SliderNav