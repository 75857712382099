import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import { GetURL } from "../../common/site/functions";

const BannerCTA = (props) => {
	let url = GetURL(props.url.id)
	return (
		<Link className="btn" to={`/${url}`}>{props.label}</Link>
	)
}

export default BannerCTA